$(function(){
    $("#link-forgot-pw").click(function(e){
        e.preventDefault();
        $('#forgetPWDmodal').modal('show');
    });
    
    $('.fs-form-wrap input').keyup(function() {
        if($('#email').val() != '' && $('#password').val() != '') {
            $('.fs-form-wrap button').attr('disabled', false);
        } else {
            $('.fs-form-wrap button').attr('disabled', true);
        }
    })
});
